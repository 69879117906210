import axios from 'axios';

import configrations from '../config/configrations';

// const API_URL = 'http://localhost:5000/api';
const API_URL = `${configrations.backendBaseUrl}/api`;

// Create a new user with token for authentication
export const createUser = (formDataToSend) => {
    const token = localStorage.getItem('token');
  
    return axios.post(`${API_URL}/users/create-user`, formDataToSend, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };
  

// Get user details by account number
export const getUserByAccountNumber = (accountNumber) => {
    const token = localStorage.getItem('token');
  
    return axios.get(`${API_URL}/users/${accountNumber}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };
  

  export const updateAmount = (accountNumber, data) => {
    const token = localStorage.getItem('token');
  
    return axios.put(`${API_URL}/users/${accountNumber}/update-amount`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

// Get the last 5 transactions for the user's passbook
export const getTransactionsByAccountNumber = (accountNumber) => {
  const token = localStorage.getItem('token');
  return axios.get(`${API_URL}/users/${accountNumber}/transactions`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};