import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css'; 
import logo1 from '../../assets/images/logo.jpeg'; 
import logo2 from '../../assets/images/newLogo.png'; 
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import { useAuth } from '../../context/AuthContext'; // Import useAuth

import configrations from '../../config/configrations';

const LoginPage = () => {
  const [logoIndex, setLogoIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const logos = [logo1, logo2]; 
  const navigate = useNavigate(); 
  const { login } = useAuth(); // Get login function from context

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLogoIndex(prevIndex => (prevIndex + 1) % logos.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [logos.length]);

  const handleLogin = async (e) => {
    e.preventDefault(); 
    setErrorMessage('');
    setIsLoading(true); 

    try {
        // const response = await axios.post('http://localhost:5000/api/login', { email, password });
        const response = await axios.post(`${configrations.backendBaseUrl}/api/login`, { email, password });
        if (response.data.token) {
            const { token, role, accountNumber } = response.data;

            // Use the context login function
            login({ token, role, accountNumber });

            // Navigate after successful login
            navigate(role === 'admin' ? '/admin-dashboard' : '/user-dashboard', { replace: true });
        } else {
            setErrorMessage('Login failed. Please try again.');
        }
    } catch (error) {
        if (error.response) {
            setErrorMessage(error.response.data.message || 'Invalid email or password');
        } else if (error.request) {
            setErrorMessage('No response from server. Please try again later.');
        } else {
            setErrorMessage('Error: ' + error.message);
        }
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <div className="login-body">
      {isLoading && <p>Loading...</p>}
      <div className="login-container">
        <section className="info">
          <section
            className="company-logo"
            style={{ backgroundImage: `url(${logos[logoIndex]})` }}
          ></section>
          <h4>Welcome to</h4>
          <h2>BR Ambedkar Fund</h2>
        </section>
        <section className="login-form">
          {/* <h2 className="text-center mt-4" style={{fontFamily:"algerian"}}>Log in</h2> */}
          <h2 className="text-center mt-4">Log in</h2>
          <form onSubmit={handleLogin} className="m-3">
            <div className="mt-4">
              {/* <label className="login-form-label" htmlFor="email" style={{fontFamily:"algerian"}}>Email</label> */}
              <label className="login-form-label" htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                required
                className='form-control '
              />
            </div>
            <div className="mt-2">
              {/* <label className="login-form-label" htmlFor="password" style={{fontFamily:"algerian"}}>Password</label> */}
              <label className="login-form-label" htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
                required
                className='form-control '
              />
            </div>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <a href="/forget-password" className="forgot-password" style={{color:"red"}}>Forgot password?</a>
            <div className="login-button-container">
              <button className="btn btn-primary" type="submit">Login</button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default LoginPage;