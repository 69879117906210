import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './navbar.css'

export default function Navbar() {
  return (
    <>
        {/* Navbar starts */}
        <nav className="navbar navbar-expand-lg sticky-top">
                <div className="container-fluid">
                    <a className="navbar-brand site-logo-container" href="/">
                        <section id="navbarLogo"></section>

                        <section id="navSiteName">
                            <span> BR Ambedkar</span>
                            <span>Fund</span>
                        </section>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="nav navbar-nav ms-auto me-5">
                            <li className="nav-item me-2">
                                <a className="nav-link text-white active" aria-current="page" href="/"><i className="fa-solid fa-house-chimney"></i>Home</a>
                            </li>
                            <li className="nav-item me-2">
                                <a className="nav-link text-white" href="/about"><i className="fa-solid fa-square-plus"></i>About</a>
                            </li>
                            <li className="nav-item me-2">
                                <a className="nav-link text-white" href="/contact"><i className="fa-solid fa-square-plus"></i>Contact Us</a>
                            </li>
                            <li className="nav-item me-2">
                                <a type="button" id="login-button" className="nav-link text-white text-center" href="/login"><i className="fa-solid fa-square-plus"></i>Login</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* Navbar ends */}
    </>
  )
}
