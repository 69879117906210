import React, { useEffect, useState } from 'react';
import { FaRupeeSign } from 'react-icons/fa';
import { BsPeopleFill, BsFillBellFill } from 'react-icons/bs';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import axios from 'axios';

import configrations from '../../../config/configrations';

function AdminDashboardHome() {
    const [totalAccounts, setTotalAccounts] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalQueries, setTotalQueries] = useState(0);
    const [chartData, setChartData] = useState([]);

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                
                // const usersRes = await axios.get('http://localhost:5000/api/users');
                const usersRes = await axios.get(`${configrations.backendBaseUrl}/api/users`);
                const users = usersRes.data;

                
                setTotalAccounts(users.length);
                const totalAmt = users.reduce((acc, user) => acc + user.totalAmount, 0);
                setTotalAmount(totalAmt);

                
                // const contactUsRes = await axios.get('http://localhost:5000/api/contactus');
                const contactUsRes = await axios.get(`${configrations.backendBaseUrl}/api/contactus`);
                const queries = contactUsRes.data;
                setTotalQueries(queries.length);

                
                const chartData = prepareChartData(users);
                setChartData(chartData);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, []);
    

    
    const prepareChartData = (users) => {
        
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        
        const monthlyTransactions = new Array(12).fill(null).map(() => ({ credit: 0, debit: 0 }));

        
        users.forEach(user => {
            user.transactions.forEach(transaction => {
                const month = new Date(transaction.date).getMonth();
                
                if (transaction.type === 'credit') {
                    monthlyTransactions[month].credit += transaction.amount;
                } else if (transaction.type === 'debit') {
                    monthlyTransactions[month].debit += transaction.amount;
                }
            });
        });

        return monthlyTransactions.map((monthData, index) => ({
            name: months[index], 
            credit: monthData.credit,
            debit: monthData.debit  
        }));
    };

   
    const getMaxTransactionAmount = (data) => {
        const maxCredit = Math.max(...data.map(item => item.credit));
        const maxDebit = Math.max(...data.map(item => item.debit));
        return Math.max(maxCredit, maxDebit);
    };

    
    const maxTransaction = getMaxTransactionAmount(chartData);
    const yAxisDomainMax = Math.ceil(maxTransaction / 25000) * 25000;

    return (
        <main className='main-container dashboardHomeMainContainer'>
            <div className='main-title'>
                <h3>DASHBOARD</h3>
            </div>

            <div className='main-cards'>
                <div className='card'>
                    <div className='card-inner'>
                        <h3 className='text-light'>Total Accounts</h3>
                        <BsPeopleFill className='card_icon text-light' />
                    </div>
                    <h1>{totalAccounts}</h1>
                </div>
                <div className='card'>
                    <div className='card-inner'>
                        <h3 className='text-light'>Total Amount</h3>
                        <FaRupeeSign className='card_icon ' />
                    </div>
                    <h1>{totalAmount}</h1>
                </div>
                <div className='card'>
                    <div className='card-inner'>
                        <h3 className='text-light'>Total Customer</h3>
                        <BsPeopleFill className='card_icon text-light' />
                    </div>
                    <h1>{totalAccounts}</h1>
                </div>
                <div className='card'>
                    <div className='card-inner'>
                        <h3 className='text-light'>All Queries</h3>
                        <BsFillBellFill className='card_icon text-light' />
                    </div>
                    <h1>{totalQueries}</h1>
                </div>
            </div>

            <div className='charts'>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" interval={0} />
                        <YAxis 
                            domain={[0, yAxisDomainMax]} 
                            tickCount={yAxisDomainMax / 25000 + 1} 
                        />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="credit" fill="#8884d8" />
                        <Bar dataKey="debit" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>

                <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" interval={0} /> 
                        <YAxis 
                            domain={[0, yAxisDomainMax]} 
                            tickCount={yAxisDomainMax / 25000 + 1}
                        />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="credit" stroke="#8884d8" />
                        <Line type="monotone" dataKey="debit" stroke="#82ca9d" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </main>
    );
}

export default AdminDashboardHome;
