import React, { useState } from 'react';
import Topbar from '../../components/topbar/Topbar';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import configrations  from '../../config/configrations';

import './contact.css';

export default function ContactUs() {
    let [message, setMessage] = useState('')
    let [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    function getInputData(e) {
        let { name, value } = e.target;
        setData(old => ({
            ...old, [name]: value
        }));
    }

    async function postData(e) {
        e.preventDefault();
     if(data.phone.length == 10){
          let response = await fetch(`${configrations.backendBaseUrl}/api/Contactus`, {
            method: "POST",
            headers: {
                "content-type": "Application/json"
            },
            body: JSON.stringify(data)
        })
        response = await response.json()
        if (response)
            setMessage(response.message)
        else
            setMessage(response.message)

        setData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        });
     }
     else
     alert('Phone Number Contain 10 Digits')

    }

    return (
        <>
        <Topbar />
        <Navbar />
            {/* <!-- Page Content --> */}
            <div className="page-heading-contact page-heading header-text">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Contact Us</h1>
                            <span>Feel free to send us a message now!</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-information">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact-item">
                                <i className="bi bi-phone"></i>
                                <h4>Phone</h4>
                                <p><a href="tel:+919756053579">+91 9756053579</a></p>
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="contact-item">
                                <i className="bi bi-envelope"></i>
                                <h4>Email</h4>
                                <a href="mailto:brambedkarfund@gmail.com">brambedkarfund@gmail.com</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="contact-item">
                                <i className="bi bi-geo-alt"></i>
                                <h4>Location</h4>
                                <p>Akbarpur Chaidri ,NayaGaon, Dhampur Road Kanth, Moradbad <b>UP 244501</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Contact Form  --> */}
            <div className="container-fluid contact-form">
                <h2 className="pt-5 pb-3">Send Your Query</h2>
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="col-md-6">
                            <h6 className='text-success mb-2'>{message}</h6>
                            <form onSubmit={postData} >
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    onChange={getInputData}

                                    style={{ borderRadius: "0" }}
                                    name="name"
                                    id="name"
                                    value={data.name}
                                    required
                                    placeholder="Your Name"
                                />
                                <input
                                    type="email"
                                    className="form-control mb-3"
                                    onChange={getInputData}

                                    style={{ borderRadius: "0" }}
                                    name="email"
                                    id="email"
                                    value={data.email}
                                    required
                                    placeholder="Your Email"
                                />
                                <input
                                    type="tel"
                                    className="form-control mb-3"
                                    onChange={getInputData}

                                    style={{ borderRadius: "0" }}
                                    name="phone"
                                    id="phone"
                                    value={data.phone}
                                    required
                                    placeholder="Your Phone Number"
                                />
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    onChange={getInputData}

                                    style={{ borderRadius: "0" }}
                                    name="subject"
                                    id="subject"
                                    value={data.subject}
                                    required
                                    placeholder="Your Subject"
                                />
                                <textarea
                                    className="form-control mb-3"
                                    onChange={getInputData}

                                    style={{ borderRadius: "0" }}
                                    name="message"
                                    id="message"
                                    value={data.message}
                                    required
                                    placeholder="Type Your Message here.."
                                ></textarea>
                                <button
                                    type="submit"
                                    className="btn btn-primary mb-3"
                                    style={{ borderRadius: "0" }}
                                >
                                    Send
                                </button>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6973.805878273141!2d78.635703!3d29.079013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390bad1ff1271441%3A0x897e40cf37919bf8!2s3JHP%2BJ74%2C%20Kasampur%2C%20Uttar%20Pradesh%20244501%2C%20India!5e0!3m2!1sen!2sus!4v1729139248662!5m2!1sen!2sus"
                                width="100%"
                                height="350"
                                style={{ border: "0" }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Map location of BR Ambedkar Fund"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
