import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

export default function OurServices() {
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="container mt-5">
        <div className="row text-center">
          {/* Card 1 */}
          <div className="col-md-4 mb-4" >
            <div className="card d-flex flex-column" style={{ backgroundColor: "white", color: "black" ,height:"350px"}}>
              <div className="card-body d-flex flex-column" style={{ backgroundColor: "white", color: "black" }}>
                <div className="icon mb-3">
                  <i className="bi bi-bank" style={{ fontSize: '3rem', color: '#D9534F' }}></i>
                </div>
                <h4 className="card-title mt-2">Fixed Deposit</h4>
                <p className="card-text mt-2">
                  We offer long-term investment opportunities, where you can enjoy a competitive interest rate upon maturity. Invest today and secure your future with the BR Ambedkar Fund.
                </p>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-4 mb-4" >
            <div className="card d-flex flex-column" style={{ backgroundColor: "white", color: "black" ,height:"350px"}}>
              <div className="card-body d-flex flex-column" style={{ backgroundColor: "white", color: "black" }}>
                <div className="icon mb-3">
                  <i className="bi bi-wallet2" style={{ fontSize: '3rem', color: '#D9534F' }}></i>
                </div>
                <h4 className="card-title mt-2">Loan</h4>
                <p className="card-text mt-2">
                  We offer different types of loans with low interest rates, making it easier for you to achieve your financial goals. Whether it's for personal or business needs, you can get a loan quickly and effortlessly with the BR Ambedkar Fund.
                </p>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-4 mb-4" >
            <div className="card d-flex flex-column" style={{ backgroundColor: "white", color: "black" ,height:"350px"}}>
              <div className="card-body d-flex flex-column" style={{ backgroundColor: "white", color: "black" }}>
                <div className="icon mb-3">
                  <i className="bi bi-currency-rupee" style={{ fontSize: '3rem', color: '#D9534F' }}></i>
                </div>
                <h4 className="card-title mt-2">Payment Request</h4>
                <p className="card-text mt-2">
                  You will be able to check your bank balance directly on your mobile, making it more convenient to manage your finances. Stay updated on your account status anytime, anywhere, and track your transactions with ease.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </>
  );
}