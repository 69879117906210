import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

// Import images
import lokeshPic from '../../assets/images/Lokesh(1).jpeg';
import sandeepPic from '../../assets/images/Sandeep.jpeg';
import shahrukhPic from '../../assets/images/shahrukh.jpeg';
import rakeshPic from '../../assets/images/Rakesh.jpeg';


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1 
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 
    }
};

const CustomCarousel = ({ deviceType }) => {
    const items = [
        {
            pic: lokeshPic,
            clientName: "LOKESH SURYA ",
            profession: 'Student',
            Discription: `Nice SERVICE !! Because of its easy-to-use UI and practical payment alternatives, I adore this site. However, in order to handle larger transaction amounts, I would like to propose including a prepaid credit limit mechanism. I also like how easy and quick the transactions were that I had on my first app use.`
        },
        {
            pic: sandeepPic,
            clientName: "Dr. SANDEEP ",
            profession: 'Doctor',
            Discription: "Better than others! BR AMBEDKAR FUND is a fantastic mobile payment application known for its security and dependability. I've been using it for a long time and find it quite enjoyable.The app supports seamless money transfers and even has a chat option to make transactions easier."
        },
        {
            pic: shahrukhPic,
            clientName: "MR. ShahRukh ",
            profession: 'Medical Service ',
            Discription: `This BR AMBEDKAR FUND provides a seamless user experience, allowing customers to handle their banking needs without the hassle of visiting a physical branch. The interface is intuitive, making it easy to navigate through various features such as checking account balance.`
        },
        {
            pic: rakeshPic,
            clientName: "DR. Rakesh  ",
            profession: 'Doctor',
            Discription: `I am using this service BR AMBEDKAR FUND from last 1 year. It's nice service, users friendly and clear. Main part is time to time nav update and not 3rd party available. We can buy Direct plan. we can overlook that, All over good service.the Transaction History page is also good.`
        },
    ];

    return (
        <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} 
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="transform .5s cubic-bezier(0.46, 0.03, 0.52, 0.96)"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            {items.map((item, index) => (
                <Paper key={index} sx={{ marginBottom: "29px", backgroundColor: '#f7f7f7' }}>
                    <div className="testimonial-item text-light rounded p-4" style={{ backgroundColor: '#003399', marginLeft: '20px', height: '27rem' }}>
                        <div className="d-flex align-items-center mb-4 carousel-img">
                            <img className="flex-shrink-0 rounded-circle border p-1" src={item.pic} alt="" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                            <div className="ms-4">
                                <h5 className="mb-1">{item.clientName}</h5>
                                <span>{item.profession}</span>
                            </div>
                        </div>
                        <p className="mb-0 text-light">{item.Discription}</p>
                    </div>
                    <Button className="CheckButton">
                    </Button>
                </Paper>
            ))}
        </Carousel>
    );
};

export default CustomCarousel;
