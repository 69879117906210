
import React, { useState } from 'react';
import { BsPersonCircle, BsJustify } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Header({ OpenSidebar }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();

  
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  
  const confirmLogout = () => {
    setShowDropdown(false); 
    setShowLogoutConfirm(true); 
  };

 
  const handleLogout = () => {
    setShowLogoutConfirm(false);
    localStorage.clear(); 
    navigate('/'); 
  };

  
  const handleProfile = () => {
    setShowDropdown(false);
   
      navigate('/admin-dashboard/settings'); 
  
  };

  return (
    <>
      <header className="header d-flex justify-content-between align-items-center p-3  text-white">
        <div className="menu-icon">
          <BsJustify className="icon" onClick={OpenSidebar} style={{ cursor: 'pointer', fontSize: '1.5rem' }} />
        </div>

        <div className="ms-auto header-right position-relative">
          <BsPersonCircle
            className="icon"
            onClick={toggleDropdown}
            style={{ cursor: 'pointer', fontSize: '1.4rem', color: 'black' }}
          />

          {showDropdown && (
            <div
              className="dropdown-menu show position-absolute"
              style={{ right: 0, top: '100%', backgroundColor: 'white', zIndex: 1000, width: '150px' }}
            >
              <button className="dropdown-item" onClick={handleProfile}>
                Profile
              </button>
              <button className="dropdown-item" onClick={confirmLogout}>
                Logout
              </button>
            </div>
          )}
        </div>
      </header>

      {showLogoutConfirm && (
        <div
          className="modal d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Logout</h5>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to logout?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={() => setShowLogoutConfirm(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-success" onClick={handleLogout}>
                  Yes, Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Header