import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import './userdashboardcss.css';
import Header from './UserDashboardHeader';
import Sidebar from './UserDashboardSidebar';

function UserDashboard() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <main className='main-container'>
        <Outlet />
      </main>
    </div>
  );
}

export default UserDashboard;