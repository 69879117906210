import React from 'react';
import './footer.css';

export default function Footer() {
  return (
    <>
            <footer>
                <div className="container">
                    <div className="container-fluid footer mt-5 py-5">
                        <div className="container py-5">
                            <div className="row g-5">
                                <div className="col-lg-3 col-md-6">
                                    <a className="navbar-brand-footer" href="/">
                                        <section id='footerImageSection' className='footerLogo'></section>
                                    </a>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <h5 className="text-white mb-4">Our Office</h5>
                                    <section className="d-flex mb-2">
                                        <span><i className="bi bi-geo-alt-fill me-3"></i></span>
                                        <span>Akbarpur Chaidri, NayaGaon, Dhampur Road Kanth, Moradbad, Uttar Pradesh, India, 244501</span>
                                    </section>
                                    <section className="d-flex mb-2">
                                        <span><i className="bi bi-telephone-fill me-3"></i></span>
                                        <span><a href="tel:+919771134034" className="text-white text-decoration-none">+91 9756053579</a></span>
                                    </section>
                                    <section className="d-flex mb-2">
                                        <span><i className="bi bi-envelope me-3"></i></span>
                                        <span><a href="mailto:brambedkarfund.com" className="text-white text-decoration-none">brambedkarfund@gmail.com</a></span>
                                    </section>
                                    <div className="d-flex pt-3 justify-content-between me-5 fs-5">
                                        <span className="social-icons"><a href="https://wa.me/9756053579" target='_blank' rel="noopener noreferrer"><i className="bi bi-whatsapp text-success"></i></a></span>
                                        <span className="social-icons"><a href="https://www.facebook.com/profile.php?id=61566930183403&mibextid=ZbWKwL" target='_blank' rel="noopener noreferrer"><i className="bi bi-facebook text-primary"></i></a></span>
                                        <span className="social-icons"><a href="https://www.instagram.com/br_ambedkar_fund" target='_blank' rel="noopener noreferrer"><i className="bi bi-instagram text-danger"></i></a></span>
                                        <span className='social-icons'><a href="https://www.linkedin.com/in/br-ambedkar-fund-4592a1332" target='_blank' rel="noopener noreferrer"><i className="bi bi-linkedin text-primary"></i></a></span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <h5 className="text-white mb-4">Quick Links</h5>
                                    <section className="d-flex flex-column ">
                                        <span className="quick-links mb-2"><i className="bi bi-chevron-right"></i><a className="text-white text-decoration-none" href="/about">About Us</a></span>
                                        <span className="quick-links mb-2"><i className="bi bi-chevron-right"></i><a className="text-white text-decoration-none" href="/contact">Contact Us</a></span>
                                        <span className="quick-links mb-2"><i className="bi bi-chevron-right"></i><a className="text-white text-decoration-none" href="/our-services">Our Services</a></span>
                                        <span className="quick-links mb-2"><i className="bi bi-chevron-right"></i><a className="text-white text-decoration-none" href="/termsandconditions">Terms & Condition</a></span>
                                    </section>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <h5 className="text-white mb-4">Business Hours</h5>
                                    <p className="mb-1">Monday - Friday</p>
                                    <h6 className="text-light">09:00 am - 07:00 pm</h6>
                                    <p className="mb-1">Saturday</p>
                                    <h6 className="text-light">09:00 am - 12:00 pm</h6>
                                    <p className="mb-1">Sunday</p>
                                    <h6 className="text-light">Closed</h6>
                                </div>
                            </div>
                        </div>
                        <hr/>
                            <div className="container text-center">
                                <p className="mb-2">Copyright &copy; 2024 BR Ambedkar Fund. All rights reserved</p>
                            </div>
                    </div>
                </div>
            </footer>
    </>
  )
}
