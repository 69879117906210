import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({
        token: localStorage.getItem('token'),
        role: localStorage.getItem('userRole'),
        accountNumber: localStorage.getItem('accountNumber'),
    });

    const login = (data) => {
        setUser({
            token: data.token,
            role: data.role,
            accountNumber: data.accountNumber,
        });
        localStorage.setItem('token', data.token);
        localStorage.setItem('userRole', data.role);
        localStorage.setItem('accountNumber', data.accountNumber);
    };

    const logout = () => {
        setUser({
            token: null,
            role: null,
            accountNumber: null,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('userRole');
        localStorage.removeItem('accountNumber');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);










// // src/context/AuthContext.js
// import React, { createContext, useState, useContext } from 'react';

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//     const [user, setUser] = useState({
//         token: null,
//         role: null,
//         accountNumber: null,
//     });

//     const login = (data) => {
//         setUser({
//             token: data.token,
//             role: data.role,
//             accountNumber: data.accountNumber,
//         });
//         localStorage.setItem('token', data.token);
//         localStorage.setItem('userRole', data.role);
//         localStorage.setItem('accountNumber', data.accountNumber);
//     };

//     const logout = () => {
//         setUser({
//             token: null,
//             role: null,
//             accountNumber: null,
//         });
//         localStorage.removeItem('token');
//         localStorage.removeItem('userRole');
//         localStorage.removeItem('accountNumber');
//     };

//     return (
//         <AuthContext.Provider value={{ user, login, logout }}>
//             {children}
//         </AuthContext.Provider>
//     );
// };

// export const useAuth = () => useContext(AuthContext);
