import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './about.css';
import Topbar from '../../components/topbar/Topbar';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

// Import images
import shushilKumarPic from '../../assets/images/ShushilKumar.jpeg';
import brajjetSinghPic from '../../assets/images/BrajjetSingh.jpeg';
import arunKumarPic from '../../assets/images/ArunKumar.jpeg';
import designerRupee from '../../assets/images/Designer-rupee.png'

export default function About() {
  const [showMore, setShowMore] = useState(false);

  const toggleMoreInfo = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <Topbar />
      <Navbar />
      <div className="page-heading-about page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>About Us</h1>
              <span>We have over 10 years of experience</span>
            </div>
          </div>
        </div>
      </div>


      {/* GET-KNOW-ABOUT-BR-AMBEKAR-FUND START */}
      <div className="more-info about-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="more-info-content1">
                <div className="row">
                  <div className="col-md-6 align-self-center">
                    <div className="right-content">
                      <span>Our solid background in finance</span>
                      <h2>Get to know about <em>BR Ambedkar Fund</em></h2>
                      <p>
                        BR AMBEDKAR FUND is trusted by 50K+ customers all over India for their financial and payment needs. With this service, you can apply online for personal loans, home loans, gold loans, etc. You can shop offline from 1 million+ products at no-cost EMI, manage investments, get insurance, and do much more.
                      </p>
                    
                      {showMore && (
                        <div>
                          <p>
                            The BR Ambedkar Fund provides financial solutions that are tailored to meet the needs of every individual.
                            Whether you are looking for investment opportunities, or require financial assistance, the Fund offers
                            a variety of options to support your goals.
                          </p>
                          <p>
                            With a strong network and a commitment to helping customers, the BR Ambedkar Fund has built a reputation
                            for trust and reliability.
                          </p>
                        </div>
                      )}

                      <Link to="#" className="filled-button btn btn-primary mb-4" onClick={toggleMoreInfo}>
                        {showMore ? 'Show Less' : 'Read More'}
                      </Link>

                    </div>
                  </div>
                  <div className="col-md-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "20" }}>
                    <div className="left-image">
                      <img src={designerRupee} alt="Shushil Kumar" height="390px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   {/* GET-KNOW-ABOUT-BR-AMBEKAR-FUND END */}

      <div className="team">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>Our team <em>members</em></h2>
                <span>Meet the minds behind our vision</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team-item">
                <img src={brajjetSinghPic} height="290px" alt="Brajjet Singh" />
                <div className="down-content">
                  <h4>Brajjet Singh</h4>
                  <span>Co-Founder</span>
                  <p>"As a co-founder, my journey is fueled by a passion for creating impactful solutions. I believe in the power of collaboration and innovation to transform ideas into reality."</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team-item">
                <img src={shushilKumarPic} height="290px" alt="Sushil Kumar" />
                <div className="down-content">
                  <h4>Sushil Kumar</h4>
                  <span>Chief Marketing Officer</span>
                  <p>"As the Chief Marketing Officer, I am driven by the challenge of connecting our vision with our audience. I believe in crafting authentic narratives that resonate, inspire, and create lasting relationships."</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team-item">
                <img src={arunKumarPic} height="290px" alt="Arun Kumar" />
                <div className="down-content">
                  <h4>Arun Kumar</h4>
                  <span>Financial Analyst</span>
                  <p>"As a Financial Analyst, I thrive on turning data into insights that drive strategic decisions. My goal is to ensure our financial health while supporting sustainable growth and innovation."</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};