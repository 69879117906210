import React from 'react';
import ShushilImage from '../../../../assets/images/ShushilKumar.jpeg'

export default function Setting() {
  const user = {
    image: ShushilImage,
    name: "Shushil Kumar",
    
    email: "brambedkarfund@gmail.com",
    phone: "+91 9756053579",
    address: "Akbarpur Chaidri ,NayaGaon, Dhampur Road Kanth, Moradbad, UP 244501"
    
  };

  return (

    <main className="main-container">
      <div className="d-flex justify-content-center">
        <h1>Welcome to BR Ambedkar Fund</h1>
      </div>
      <div
        className="container-fluid card "
        style={{ border: "2px solid blue", backgroundColor: "#1a237e " }}
      >
        <div className="row">
          <div className="col-md-4 mb-2 mt-2">
            {user.image && (
              <img
                src={`${user.image}`}
                style={{ borderRadius: "50%", height: "110px", width: "110px" , marginLeft: "20px"}}
                alt=""
              />
            )}
          </div>
          <div className="col-md-4 mb-2 mt-2">
            <div
              className="card bordered"
              style={{
                backgroundColor: "#1a237e",
                color: "white",
                height: "110px",
              }}
            >
              <h6 className="mt-3 text-warning">Name.</h6>
              <p>{user.name}</p>
            </div>
          </div>          

<div className="col-md-4 mb-2 mt-2">
            <div
              className="card"
              style={{
                backgroundColor: "#1a237e",
                color: "white",
                height: "110px",
              }}
            >
              <h6 className="mt-3 text-warning">Email.</h6>
              <p>{user.email}</p>
            </div>
          </div>




        </div>
        <div className="row">
          <div className="col-md-4 mb-2 mt-2"></div>
          
          <div className="col-md-4 mb-2 mt-2">
            <div
              className="card"
              style={{
                backgroundColor: "#1a237e",
                color: "white",
                height: "110px",
              }}
            >
              <h6 className="mt-3 text-warning">Phone.</h6>
              <p>{user.phone}</p>
            </div>
          </div>
          <div className="col-md-4 mb-2 mt-2">
            <div
              className="card"
              style={{
                backgroundColor: "#1a237e",
                color: "white",
                height: "110px",
              }}
            >
              <h6 className="mt-3 text-warning">Address.</h6>
              <p>{user.address}</p>
            </div>
          </div>
        </div>

        <div className="card-bodye mt-5">
          <p style={{ fontSize: "14px", marginTop: "10px", color: "white" }}>
            <span className="text-warning">Note :</span> Welcome back, {user.name}!
            If any of your account details are incorrect, please contact the
            Ambedkar Fund team for assistance.
          </p>
        </div>
      </div>
    </main>
  );
}