import React, { useEffect, useState } from "react";
import axios from "axios";
import configrations from "../../../config/configrations.js";

import QrCode from "../../../assets/images/QRcode.jpeg";

function DashboardHome() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const accountNumber = localStorage.getItem("accountNumber");
      const token = localStorage.getItem("token");

      if (!accountNumber) {
        setError("Account number not found in local storage.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${configrations.backendBaseUrl}/api/users/${accountNumber}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser(response.data);
      } catch (err) {
        setError(
          "Error fetching user details: " + (err.response?.data || err.message)
        );
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  console.log(`user image : ${configrations.backendBaseUrl}${user.image}`);
  return (
    <>
      <main className="main-container">
        <div className="d-flex justify-content-center">
          <h1>Welcome to BR Ambedkar Fund</h1>
        </div>
        <div
          className="container-fluid card "
          style={{ border: "2px solid blue", backgroundColor: "#1a237e " }}
        >
          <div className="row">
            <div className="col-md-4 mb-2 mt-2">
              {user.image && (
                <img
                  src={`${configrations.backendBaseUrl}${user.image}`}
                  style={{
                    borderRadius: "50%",
                    height: "110px",
                    width: "110px",
                  }}
                  alt=""
                />
              )}
            </div>
            <div className="col-md-4 mb-2 mt-2">
              <div
                className="card bordered"
                style={{
                  backgroundColor: "#1a237e",
                  color: "white",
                  height: "110px",
                }}
              >
                <h6 className="mt-3 text-warning">Name.</h6>
                <p>{user.name}</p>
              </div>
            </div>
            <div className="col-md-4 mb-2 mt-2">
              <div
                className="card"
                style={{
                  backgroundColor: "#1a237e",
                  color: "white",
                  height: "110px",
                }}
              >
                <h6 className="mt-3 text-warning">Account Number.</h6>
                <p>{user.accountNumber}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2 mt-2"></div>
            <div className="col-md-4 mb-2 mt-2">
              <div
                className="card"
                style={{
                  backgroundColor: "#1a237e",
                  color: "white",
                  height: "110px",
                }}
              >
                <h6 className="mt-3 text-warning">Email.</h6>
                <p>{user.email}</p>
              </div>
            </div>
            <div className="col-md-4 mb-2 mt-2">
              <div
                className="card"
                style={{
                  backgroundColor: "#1a237e",
                  color: "white",
                  height: "110px",
                }}
              >
                <h6 className="mt-3 text-warning">Phone.</h6>
                <p>{user.phone}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card mb-2" style={{ backgroundColor: "#1a237e " }}>
              <div className="card-bodye">
                <p
                  style={{
                    marginTop: "10px",
                    color: "white",
                    textAlign: "end",
                    fontSize: "35px",
                  }}
                >
                  <span className="text-warning" style={{ fontSize: "32px" }}>
                    Available Balance :
                  </span>{" "}
                  {user.totalAmount}{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="card-bodye mt-5">
            <p style={{ fontSize: "14px", marginTop: "10px", color: "white" }}>
              <span className="text-warning">Note :</span> Welcome back,{" "}
              {user.name}! If any of your account details are incorrect, please
              contact the Ambedkar Fund team for assistance.
            </p>
          </div>

          <div className="text-end">
              {/* <!-- Button trigger modal --> */}
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Pay Now!
              </button>
            </div>

        </div>

        

      </main>

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {" "}
                SUSHIL KUMAR
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-center">
              <img
                src={QrCode}
                style={{ height: "450px", width: "100%" }}
                alt="QR Code"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardHome;
