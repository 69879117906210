import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ requiredRole, element }) => {
    const { user } = useAuth();

    // Check if the user is logged in and has a valid role
    const isLoggedIn = user.token && user.role;
    const hasRequiredRole = isLoggedIn && user.role === requiredRole;

    if (!isLoggedIn) {
        // Not logged in, redirect to login
        return <Navigate to="/login" />;
    }

    if (!hasRequiredRole) {
        // User does not have the right role, redirect to home or appropriate page
        return <Navigate to="/" />;
    }

    return element;
};

export default PrivateRoute;

