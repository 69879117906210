import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Login/login.css';
import logo1 from '../../assets/images/logo.jpeg'

import configrations from '../../config/configrations';

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const email = location.state?.email;

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
     
    localStorage.getItem('password-reset-username')
     
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      // const response = await fetch('http://localhost:5000/api/users/reset-password', {
        const response = await fetch(`${configrations.backendBaseUrl}/api/users/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      const result = await response.json();

      if (response.ok) {
        navigate('/login');
      } else {
        setErrorMessage(result.message || 'An error occurred');
      }
    } catch (error) {
      setErrorMessage('Failed to reset password.');
    }
  };

  return (
    <div className="login-body">
      <div className="login-container">
        <section className="info">
          <section className="commpany-logo" style={{ backgroundImage: `url(${logo1})` }}></section>
          <h4>Welcome to</h4>
          <h2>BR Ambedkar Fund</h2>
        </section>
        <section className="login-form">
          <h2 className="text-center mt-4">Reset Password</h2>
          <form onSubmit={handleSubmit} className="m-3">
            <div className="mt-4">
              <label className="login-form-label" htmlFor="password">New Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                required
                autoComplete="off"
                className="form-control"
              />
            </div>
            <div className="mt-2">
              <label className="login-form-label" htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                id="confirm-password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
                autoComplete="off"
                className="form-control"
              />
            </div>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <div className="login-button-container">
              <button className="login-button" type="submit">Reset</button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default ResetPassword;
