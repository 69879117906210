import React from "react";
import {
    BsGrid1X2Fill,
    BsBook,
} from "react-icons/bs";

import { Link } from "react-router-dom";

import logo2 from "../../../assets/images/logo2.png";

function Sidebar({ openSidebarToggle, OpenSidebar }) {
    return (
        <aside
            id="sidebar"
            className={openSidebarToggle ? "sidebar-responsive" : ""}
        >
            <div className="sidebar-title">
                <div
                    className="sidebar-brand"
                    style={{
                        marginTop: "15px",
                        height: "8rem",
                        width: "10rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <img
                        src={logo2}
                        alt="Loading..."
                        style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            objectFit: "contain",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 1)",
                        }}
                    />
                </div>

                <span className="icon close_icon" onClick={OpenSidebar}>
                    X
                </span>
            </div>

            <ul className="sidebar-list">
                <li className='sidebar-list-item'>
                    <Link to="/user-dashboard">
                        <BsGrid1X2Fill className='icon' /> Dashboard
                    </Link>
                </li>
                <li className='sidebar-list-item'>
                    <Link to="/user-dashboard/passbook">
                        <BsBook className='icon' /> PassBook
                    </Link>
                </li>
            </ul>
        </aside>
    );
}

export default Sidebar;
