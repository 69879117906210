import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import './admindashboardcss.css';
import Header from './AdminDashboardHeader';
import Sidebar from './AdminDashboardSidebar';

function AdminDashboard() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    window.history.forward();
}, []);

  return (
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <main className='main-container'>
        <Outlet /> 
      </main>
    </div>
  );
}

export default AdminDashboard;