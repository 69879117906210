import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './home.css';

import Topbar from '../../components/topbar/Topbar';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import Carousel from '../../components/carousel/Carousel';

import { Link } from 'react-router-dom';

const Home = () => {
    
    
    return (
        <div>
            <Topbar />

            <Navbar />

            
            <div className="home-intro-section">
                <section className="intro-text">Investment that Matters</section>
                <section className="intro-image fadeIn-img" ></section>
            </div>
           
<div className="services">
                <div className="container-fluid">
                    <div className="row service-row">
                        <h2 className="text-center">Financial Services</h2>
                        <span className=" mb-5 finance-span ">
                            We are mainly focused on the finance ,loan service category so, we provide finace ,loan service related content if you are interested in the finace ,loan service category then you can visit daily to get more latest information.
                            This BR AMBEDKAR FUND provides a seamless user experience, allowing customers to handle their banking needs without the hassle of visiting a physical branch. The interface is intuitive, making it easy to navigate through various features such as checking account balances, transferring funds, paying bills, and managing investments. The app's design is clean and user-friendly, with clear menu Overall, the bank app is a convenient and efficient tool for managing finances on the go, providing a seamless exp.
                        </span>

                    </div>

                    <div className="row text-center">
                        
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4" >
                            <div className="card d-flex flex-column" style={{ borderTop: "4px solid blue", borderBottom: "4px solid blue", borderRadius: "20px", backgroundColor: "white", color: "black", height: "350px" }}>
                                <div className="card-body d-flex flex-column" style={{ backgroundColor: "white", color: "black" }}>
                                    <div className="icon mb-3">
                                        <i className="bi bi-bank" style={{ fontSize: '3rem', color: 'blue' }}></i>
                                    </div>
                                    <h4 className="card-title mt-2 ">Fixed Deposit</h4>
                                    <p className="card-text mt-2">
                                        We offer long-term investment opportunities, where you can enjoy a competitive interest rate upon maturity. Invest today and secure your future with the BR Ambedkar Fund.
                                    </p>
                                </div>
                                <div className="text-center mt-2">
                                    <Link to="https://www.facebook.com/profile.php?id=61566930183403&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="me-3">
                                        <i className="bi bi-facebook" style={{ fontSize: '1.3rem', color: 'blue' }}></i>
                                    </Link>
                                    <Link to="https://www.instagram.com/skrao1998/profilecard/?igsh=MXkweHhlMm80ZWt2OQ==" target="_blank" rel="noopener noreferrer" className="me-3">
                                        <i className="bi bi-instagram" style={{ fontSize: '1.3rem', color: 'red' }}></i>
                                    </Link>
                                    <Link to="https://wa.me/+91 97560 53579" target="_blank" rel="noopener noreferrer" className="me-3">
                                        <i className="bi bi-whatsapp" style={{ fontSize: '1.3rem', color: 'green' }}></i>
                                    </Link>
                                    <Link to="https://www.linkedin.com/in/br-ambedkar-fund-4592a1332?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-linkedin" style={{ fontSize: '1.3rem', color: 'blue' }}></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div
                                className="card d-flex flex-column justify-content-between loan"
                                style={{
                                    borderTop: "4px solid blue",
                                    borderBottom: "4px solid blue",
                                    borderRadius: "20px",
                                    backgroundColor: "white",
                                    color: "black",
                                    height: "350px",
                                }}
                            >
                                <div
                                    className="card-body d-flex flex-column justify-content-between"
                                    style={{ backgroundColor: "white", color: "black" }}
                                >
                                    <div>
                                        <div className="icon mb-3">
                                            <i className="bi bi-wallet2" style={{ fontSize: "3rem", color: "blue" }}></i>
                                        </div>
                                        <h4 className="card-title mt-2">Loan</h4>
                                        <p className="card-text mt-2">
                                        We offer various loans with low interest rates, helping you achieve your financial goals effortlessly. Whether for personal or business needs, the BR Ambedkar Fund makes it easy
                                        </p>
                                    </div>

                                    
                                    <div className="text-center mt-3">
                                        <Link
                                            to="https://www.facebook.com/profile.php?id=61566930183403&mibextid=ZbWKwL"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="me-3"
                                        >
                                            <i className="bi bi-facebook" style={{ fontSize: "1.3rem", color: "blue" }}></i>
                                        </Link>
                                        <Link
                                            to="https://www.instagram.com/skrao1998/profilecard/?igsh=MXkweHhlMm80ZWt2OQ=="
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="me-3"
                                        >
                                            <i className="bi bi-instagram" style={{ fontSize: "1.3rem", color: "red" }}></i>
                                        </Link>
                                        <Link
                                            to="https://wa.me/+91 97560 53579"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="me-3"
                                        >
                                            <i className="bi bi-whatsapp" style={{ fontSize: "1.3rem", color: "green" }}></i>
                                        </Link>
                                        <Link
                                            to="https://www.linkedin.com/in/br-ambedkar-fund-4592a1332?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="bi bi-linkedin" style={{ fontSize: "1.3rem", color: "blue" }}></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div
                                className="card d-flex flex-column justify-content-between"
                                style={{
                                    borderTop: "4px solid blue",
                                    borderBottom: "4px solid blue",
                                    borderRadius: "20px",
                                    backgroundColor: "white",
                                    color: "black",
                                    height: "350px",
                                }}
                            >
                                <div
                                    className="card-body d-flex flex-column justify-content-between"
                                    style={{ backgroundColor: "white", color: "black" }}
                                >
                                    <div>
                                        <div className="icon mb-3">
                                            <i className="bi bi-currency-rupee" style={{ fontSize: "3rem", color: "blue" }}></i>
                                        </div>
                                        <h4 className="card-title mt-2">Payment Request</h4>
                                        <p className="card-text mt-2">
                                            You can easily check your bank balance on your mobile, making it convenient to manage your finances and track transactions.
                                        </p>
                                    </div>

                                    
                                    <div className="text-center mt-4">
                                        <Link
                                            to="https://www.facebook.com/profile.php?id=61566930183403&mibextid=ZbWKwL"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="me-3"
                                        >
                                            <i className="bi bi-facebook" style={{ fontSize: "1.3rem", color: "blue" }}></i>
                                        </Link>
                                        <Link
                                            to="https://www.instagram.com/skrao1998/profilecard/?igsh=MXkweHhlMm80ZWt2OQ=="
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="me-3"
                                        >
                                            <i className="bi bi-instagram" style={{ fontSize: "1.3rem", color: "red" }}></i>
                                        </Link>
                                        <Link
                                            to="https://wa.me/+91 97560 53579"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="me-3"
                                        >
                                            <i className="bi bi-whatsapp" style={{ fontSize: "1.3rem", color: "green" }}></i>
                                        </Link>
                                        <Link
                                            to="https://www.linkedin.com/in/br-ambedkar-fund-4592a1332?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="bi bi-linkedin" style={{ fontSize: "1.3rem", color: "blue" }}></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='row text-center'>
                    <div className="text-center">
                        <h3 className=' fb-bold' style={{ borderBottom: '3px solid blue',display: 'inline-block', paddingBottom: '5px', marginBottom: '2    5px' }}>
                        Invest Wisely: Uncover Our Attractive Fixed Deposit Rates!
                        </h3>
                    </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <div className="card bg">
                                <div className="card-body service-card-body">
                                    <div className="d-flex flex-wrap justify-content-between service-header">
                                        <h5 className="card-title">Fixed Deposit</h5>
                                        <span className="material-symbols-outlined fixeddeposit-icon">assured_workload</span>
                                    </div>
                                    <hr />
                                    <ul>
                                        <li className="d-flex flex-wrap justify-content-between service-header">
                                            <span>Duration</span>
                                            <span>12 Month</span>
                                        </li>
                                        <li className="d-flex flex-wrap justify-content-between">
                                            <span>Interest Rate</span>
                                            <span>10.00 %</span>
                                        </li>
                                        <li className="d-flex flex-wrap justify-content-between">
                                            <span>Minimum</span>
                                            <span>₹ 500.00</span>
                                        </li>
                                        <li className="d-flex flex-wrap justify-content-between">
                                            <span>Maximum</span>
                                            <span>₹ 10,000.00</span>
                                        </li>
                                    </ul>
                                    <hr />
                                    <Link to="/contact" className="btn btn-primary">Apply Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <div className="card bg">
                                <div className="card-body service-card-body">
                                    <div className="d-flex flex-wrap justify-content-between service-header">
                                        <h5 className="card-title">Fixed Deposit</h5>
                                        <span className="material-symbols-outlined fixeddeposit-icon">assured_workload</span>
                                    </div>
                                    <hr />
                                    <ul>
                                        <li className="d-flex flex-wrap justify-content-between service-header">
                                            <span>Duration</span>
                                            <span>24 Month</span>
                                        </li>
                                        <li className="d-flex flex-wrap justify-content-between">
                                            <span>Interest Rate</span>
                                            <span>12.00 %</span>
                                        </li>
                                        <li className="d-flex flex-wrap justify-content-between">
                                            <span>Minimum</span>
                                            <span>₹ 1000.00</span>
                                        </li>
                                        <li className="d-flex flex-wrap justify-content-between">
                                            <span>Maximum</span>
                                            <span>₹ 20,000.00</span>
                                        </li>
                                    </ul>
                                    <hr />
                                    <Link to="/contact" className="btn btn-primary">Apply Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <div className="card bg">
                                <div className="card-body service-card-body">
                                    <div className="d-flex flex-wrap justify-content-between service-header">
                                        <h5 className="card-title">Fixed Deposit</h5>
                                        <span className="material-symbols-outlined fixeddeposit-icon">assured_workload</span>
                                    </div>
                                    <hr />
                                    <ul>
                                        <li className="d-flex flex-wrap justify-content-between service-header">
                                            <span>Duration</span>
                                            <span>36 Month</span>
                                        </li>
                                        <li className="d-flex flex-wrap justify-content-between">
                                            <span>Interest Rate</span>
                                            <span>14.00 %</span>
                                        </li>
                                        <li className="d-flex flex-wrap justify-content-between">
                                            <span>Minimum</span>
                                            <span>₹ 1000.00</span>
                                        </li>
                                        <li className="d-flex flex-wrap justify-content-between">
                                            <span>Maximum</span>
                                            <span>₹ 30,000.00</span>
                                        </li>
                                    </ul>
                                    <hr />
                                    <Link to="/contact" className="btn btn-primary">Apply Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* Services Section Ends */}

            {/* Why Choose Us Section Starts */}
            <section className="why-choose-us container-fluid  mt-5" id="why-choose-us">
                <div className="row ">
                    <div className="col-md-12 why-choose-us-heading">

                        <h2>Why Choose Us</h2>
                        <p>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                            alteration in some form, by injected humour, or randomised words which don't look even slightly
                            believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't
                            anything
                            embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet.
                        </p>
                        <div className="row">
                            <div className="col-sm-4 mb-3 mb-sm-0">
                                <div className="card">
                                    <div className="card-body why-choose-us-card-body">
                                        <span className="material-symbols-outlined">
                                            quick_reference_all
                                        </span>
                                        <h5> We're Quick </h5>

                                        <p className="card-text">Our Financing processes designed to disburse fund with minimal
                                            paperwork and a quick turnaround.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 mb-3 mb-sm-0">
                                <div className="card">
                                    <div className="card-body why-choose-us-card-body">
                                        <span className="material-symbols-outlined">
                                            hub
                                        </span>
                                        <h5 className="card-title">We're Convenient</h5>
                                        <p className="card-text">Our Website ensoure you can manage your loans.payments and
                                            invetsments anywhere anytime</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="card">
                                    <div className="card-body why-choose-us-card-body">
                                        <span className="material-symbols-outlined">
                                            in_home_mode
                                        </span>
                                        <h5 className="card-title">We're Next door</h5>
                                        <p className="card-text">With a Network of 1+ Branches and door to door services. we are
                                            always by your side.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="counters">
                            <div className="counter">
                                <span className="count" data-target="1000">1000+</span> 
                                <p>Satisfied Customers</p>
                            </div>
                            <div className="counter">
                                <span className="count" data-target="26">50+</span>
                                <p>Villages Covered</p>
                            </div>
                            <div className="counter">
                                <span className="count" data-target="40">10+</span>
                                <p>Cities Covered</p>
                            </div>
                            <div className="counter">
                                <span className="count" data-target="1">3+</span>
                                <p>Branches in India</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {/* Why Choose Us Section Ends */}
            {/* What They Say About Us Section Starts */}

            <div className="testimonials mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-heading what-they-say-heading">
                                <h2>What they say about us</h2>
                            </div>
                        </div>
                        <div>
                            <Carousel />
                        </div>
                    </div>
                </div>
            </div>

            {/* What They Say About Us Section Ends */}

            
            {/* Helpline Section Starts */}

            <div className="container-fluid mb-5 mt-5 helpline">
                <div className="row mt-2">
                    <div className="col-md-4 col-sm-12 order-md-1 order-sm-2">
                        <section className="helpline-img"></section>
                    </div>
                    <div className="col-md-8 col-sm-12 bg-light helpline-card-center  order-md-2 order-sm-3">
                        <div className='helpline-icon mt-3'>
                            <section className="helplineicon1"></section>
                            <section className="helplineicon2"></section>
                        </div>
                        <span>DIAL <b>1930</b> FOR ONLINE FINANCIAL FRAUD</span>
                        <hr />
                        <span className="">Report any Cybercrime at &nbsp;<a href='https://www.cybercrime.gov.in' target='_blank' rel="noopener noreferrer"><b> WWW.CYBERCRIME.GOV.IN</b></a></span>

                    </div>


                </div>
            </div>
            {/* Helpline Section Ends */}

            <Footer />

        </div>
    );
};

export default Home;
