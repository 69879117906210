import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 

import "../Login/login.css";
import logo1 from "../../assets/images/logo.jpeg";

import configrations from "../../config/configrations";

export default function ForgetPassword1() {
  const [formData, setFormData] = useState({ email: "" });
  const [errormessage, SetErrorMessage] = useState("");
  let navigate = useNavigate();

  function getInputData(e) {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  async function postData(e) {
    e.preventDefault();

    // let response = await fetch("http://localhost:5000/api/users/forget-Password-1", {
    let response = await fetch(`${configrations.backendBaseUrl}/api/users/forget-Password-1`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email: formData.email }),
    });
    response = await response.json();

    if (response.status === 200) {
      localStorage.setItem("password-reset-username", formData.email);
      navigate("/verify-otp");
    } else {
      SetErrorMessage("Invalid username");
    }
  }

  return (
    <>
      <div className="login-body">
        <div className="login-container">
          <section className="info">
            <section
              className="commpany-logo"
              style={{ backgroundImage: `url(${logo1})` }}
            ></section>
            <h4>Welcome to</h4>
            <h2>BR Ambedkar Fund</h2>
          </section>
          <section className="login-form container-fluid">
            <h2 className="mt-4 text-center" style={{fontFamily:'algerian'}}>Forgot Password</h2>
            <form onSubmit={postData} className="mt-2">
              <div className="mt-4">
                <label className="mb-1" style={{fontFamily:'algerian'}}> Email<span className="text-danger">*</span></label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={getInputData}
                  required
                  className="form-control"
                  autoComplete="off"
                  placeholder="Enter UserName"
                />
                <div className="d-flex justify-content-between mb-1 mt-0">
                  {errormessage ? (
                    <p className="text-danger">{errormessage}</p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              <div className="login-button-container">
                <button className="btn btn-primary mb-3" type="submit">
                  Send OTP
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  );
}
