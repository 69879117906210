import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import ContactUs from './pages/Contact/Contact';
import TermsAndCondition from './pages/terms&conditions/Terms&Conditions';
import OurServices from './pages/ourServices/Services';

import ForgetPassword1 from './pages/ForgotPassword/ForgetPassword1';
import VerifyOTP from './pages/ForgotPassword/VerifyOTP';
import ResetPassword from './pages/ForgotPassword/ResetPassword';
import Profile from './pages/Profile/Profile';
import AdminDashboard from './pages/Admin/Dashboard/AdminDashboard';
import AdminDashboardHome from './pages/Admin/Dashboard/AdminDashbaordHome';
import Customers from './pages/Admin/Dashboard/tabs/Customers';
import CreateUser from './pages/Admin/Dashboard/tabs/CreateUser';
import CreditDebitAmount from './pages/Admin/Dashboard/tabs/CreditDebitAmount';
import Ticket from './pages/Admin/Dashboard/tabs/Tickets';
import Setting from './pages/Admin/Dashboard/tabs/Settings';
import UserDashboard from './pages/User/Dashboard/UserDashboard'; 
import UserDashboardHome from './pages/User/Dashboard/UserDashbaordHome';
import PrivateRoute from './components/PrivateRoute';
import Passbook from './pages/User/Dashboard/tabs/Passbook';
import { useAuth } from './context/AuthContext';

function App() {
    const { user } = useAuth(); // Access user from Auth context

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path='/termsandconditions' element={<TermsAndCondition />} />
                <Route path='/our-services' element={<OurServices />} />

                {/* Admin Dashboard Routes */}
                <Route path='/admin-dashboard' element={<PrivateRoute requiredRole='admin' element={<AdminDashboard />} />}>
                    <Route index element={<PrivateRoute requiredRole='admin' element={<AdminDashboardHome />} />} />
                    <Route path='customers' element={<PrivateRoute requiredRole='admin' element={<Customers />} />} />
                    <Route path='create-customer' element={<PrivateRoute requiredRole='admin' element={<CreateUser />} />} />
                    <Route path='credit-debit' element={<PrivateRoute requiredRole='admin' element={<CreditDebitAmount />} />} />
                    <Route path='tickets' element={<PrivateRoute requiredRole='admin' element={<Ticket />} />} />
                    <Route path='settings' element={<PrivateRoute requiredRole='admin' element={<Setting />} />} />
                </Route>

                {/* User Dashboard Route */}
                <Route path='/user-dashboard' element={<PrivateRoute requiredRole='user' element={<UserDashboard />} />}>
                    <Route index element={<PrivateRoute requiredRole='user' element={<UserDashboardHome />} />} />
                    <Route path='passbook' element={<PrivateRoute requiredRole='user' element={<Passbook />} />} />
                </Route>

                {/* Password Recovery Routes */}
                <Route path="/forget-password" element={<ForgetPassword1 />} />
                <Route path="/verify-otp" element={<VerifyOTP />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path='/profile' element={<PrivateRoute requiredRole='user' element={<Profile />} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;








// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import './App.css';
// import Login from './pages/Login/Login';
// import Home from './pages/Home/Home';
// import About from './pages/About/About';
// import ContactUs from './pages/Contact/Contact';
// import TermsAndCondition from './pages/terms&conditions/Terms&Conditions';
// import OurServices from './pages/ourServices/Services';

// import ForgetPassword1 from './pages/ForgotPassword/ForgetPassword1';
// import VerifyOTP from './pages/ForgotPassword/VerifyOTP';
// import ResetPassword from './pages/ForgotPassword/ResetPassword';
// import Profile from './pages/Profile/Profile';
// import AdminDashboard from './pages/Admin/Dashboard/AdminDashboard';
// import AdminDashboardHome from './pages/Admin/Dashboard/AdminDashbaordHome';
// import Customers from './pages/Admin/Dashboard/tabs/Customers';
// import CreateUser from './pages/Admin/Dashboard/tabs/CreateUser';
// import CreditDebitAmount from './pages/Admin/Dashboard/tabs/CreditDebitAmount';
// import Ticket from './pages/Admin/Dashboard/tabs/Tickets';
// import Setting from './pages/Admin/Dashboard/tabs/Settings';
// import UserDashboard from './pages/User/Dashboard/UserDashboard'; 
// import UserDashboardHome from './pages/User/Dashboard/UserDashbaordHome';
// import PrivateRoute from './components/PrivateRoute';
// import Passbook from './pages/User/Dashboard/tabs/Passbook';

// function App() {
//     const userRole = localStorage.getItem('userRole');

//     return (
//         <BrowserRouter>
//             <Routes>
//                 <Route path='/login' element={<Login />} />
//                 <Route path='/' element={<Home />} />
//                 <Route path='/about' element={<About />} />
//                 <Route path='/contact' element={<ContactUs />} />
//                 <Route path='/termsandconditions' element={<TermsAndCondition />} />
//                 <Route path='/our-services' element={<OurServices />} />

//                 {/* Admin Dashboard Routes */}
//                 <Route path='/admin-dashboard' element={<PrivateRoute userRole={userRole} requiredRole='admin' element={<AdminDashboard />} />}>
//                     <Route index element={<PrivateRoute userRole={userRole} requiredRole='admin' element={<AdminDashboardHome />} />} />
//                     <Route path='customers' element={<PrivateRoute userRole={userRole} requiredRole='admin' element={<Customers />} />} />
//                     <Route path='create-customer' element={<PrivateRoute userRole={userRole} requiredRole='admin' element={<CreateUser />} />} />
//                     <Route path='credit-debit' element={<PrivateRoute userRole={userRole} requiredRole='admin' element={<CreditDebitAmount />} />} />
//                     <Route path='tickets' element={<PrivateRoute userRole={userRole} requiredRole='admin' element={<Ticket />} />} />
//                     <Route path='settings' element={<PrivateRoute userRole={userRole} requiredRole='admin' element={<Setting />} />} />
//                 </Route>

//                 {/* User Dashboard Route */}
//                 <Route path='/user-dashboard' element={<PrivateRoute userRole={userRole} requiredRole='user' element={<UserDashboard />} />} >
//                     <Route index element={<PrivateRoute userRole={userRole} requiredRole='user' element={<UserDashboardHome />} />} />
//                     <Route path='passbook' element={<PrivateRoute userRole={userRole} requiredRole='user' element={<Passbook />} />} />
//                 </Route>

//                 {/* Password Recovery Routes */}
//                 <Route path="/forget-password" element={<ForgetPassword1 />} />
//                 <Route path="/verify-otp" element={<VerifyOTP />} />
//                 <Route path="/reset-password" element={<ResetPassword />} />
//                 <Route path='/profile' element={<PrivateRoute userRole={userRole} requiredRole='user' element={<Profile />} />} />
//             </Routes>
//         </BrowserRouter>
//     );
// }

// export default App;