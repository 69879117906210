import React from 'react'
import './topbar.css';

export default function Topbar() {
  return (
    <>
        {/* Topbar Start */}
        <div className="container-fluid topbar px-0">
                <div className="row g-0 d-none d-lg-flex">  
                    <div className="col-lg-6 ps-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center text-white">
                            <span>Follow Us:</span>
                            <a className="btn btn-link text-light" href="https://wa.me/9756053579" target='_blank' rel="noopener noreferrer"><i className="bi bi-whatsapp"></i></a>
                            <a className="btn btn-link text-light" href="https://www.facebook.com/profile.php?id=61566930183403&mibextid=ZbWKwL" target='_blank' rel="noopener noreferrer"><i className="bi bi-facebook"></i></a>
                            <a className="btn btn-link text-light" href="https://www.instagram.com/br_ambedkar_fund" target='_blank' rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
                            <a className="btn btn-link text-light" href="https://www.linkedin.com/in/br-ambedkar-fund-4592a1332" target='_blank' rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end">
                        <div className="h-100 topbar-right d-inline-flex align-items-center text-white py-2 px-5">
                            <span className="fs-5 fw-bold me-2"><i className="bi bi-telephone-fill me-2"></i>Call Us:</span>
                            <span className="fs-5 fw-bold"><a href="tel:+919771134034" className="text-light text-decoration-none">+91 9756053579</a></span>
                        </div>
                    </div>
                </div>
            </div>
            {/* Topbar End */}
    </>
  )
}
