import React from 'react'
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


export default function TermAndCondition() {
    return (
        <>
            <div>
                <Navbar />
                <div className="container-fluid mt-4">
                    <div className="text-center">
                        <h3 className=' fb-bold' style={{ borderBottom: '3px solid blue',display: 'inline-block', paddingBottom: '5px' }}>
                            Terms & Conditions
                        </h3>
                    </div>
                    <div className="accordion mt-3" id="termsAccordion">
                        {/* English terms accordion item */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#englishTerms" aria-expanded="true" aria-controls="englishTerms">
                                    Terms & Conditions
                                </button>
                            </h2>
                            <div id="englishTerms" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#termsAccordion">
                                <div className="accordion-body">
                                    <ul>
                                        <li>When a user opens an account in the <strong>BR Ambedkar Fund</strong>, they are required to submit the following documents/details:
                                            <ul className='mt-2' >
                                                <li> Aadhar Card</li>
                                                <li> PAN Card</li>
                                                <li> Mobile Number</li>
                                                <li> Email Address</li>
                                                <li> 2 Passport Size Photos</li>
                                            </ul>
                                        </li>
                                        <li className='mt-2'>The account holder must be at least 14 years old.</li>
                                        <li>The account holder must pay ₹100 for the passbook.</li>
                                        <li>If the account holder loses or forgets the passbook, the bank details will still be valid for authorization.</li>
                                        <li>To maintain the account, the minimum balance should be ₹200.</li>
                                        <li>When adding money to the account, the minimum deposit amount should be ₹50.</li>
                                        <li>If the account holder has any queries or issues, they should submit a written complaint letter or visit the "Contact Us" page to send their query.</li>
                                        <li>If the account holder uses the fixed deposit service for one year, they will receive a 10% increment on the deposited amount.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Hindi terms accordion item */}
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hindiTerms" aria-expanded="false" aria-controls="hindiTerms">
                                    नियम और शर्तें
                                </button>
                            </h2>
                            <div id="hindiTerms" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#termsAccordion">
                                <div className="accordion-body">
                                    <ul>
                                        <li>जब कोई उपयोगकर्ता <strong>बी.आर. अम्बेडकर फंड</strong> में खाता खोलता है, तो उन्हें निम्नलिखित दस्तावेज़/विवरण जमा करने होंगे:
                                            <ul className='mt-2'>
                                                <li> आधार कार्ड</li>
                                                <li> पैन कार्ड</li>
                                                <li> मोबाइल नंबर</li>
                                                <li> ईमेल पता</li>
                                                <li> 2 पासपोर्ट साइज फोटो</li>
                                            </ul>
                                        </li>
                                        <li className='mt-2' >खाता धारक की उम्र कम से कम 14 साल होनी चाहिए।</li>
                                        <li>पासबुक के लिए खाता धारक को ₹100 का भुगतान करना होगा।</li>
                                        <li>यदि खाता धारक पासबुक खो देता है या भूल जाता है, तो बैंक विवरण अभी भी प्रमाणीकरण के लिए मान्य होगा।</li>
                                        <li>खाते को बनाए रखने के लिए न्यूनतम शेष राशि ₹200 होनी चाहिए।</li>
                                        <li>खाते में धनराशि जोड़ते समय, न्यूनतम जमा राशि ₹50 होनी चाहिए।</li>
                                        <li>यदि खाता धारक के पास कोई प्रश्न या समस्या है, तो उन्हें लिखित शिकायत पत्र जमा करना चाहिए या "Contact US" पृष्ठ पर जाकर अपनी समस्या भेजनी चाहिए।</li>
                                        <li>यदि खाता धारक एक वर्ष के लिए फिक्स्ड डिपॉजिट सेवा का उपयोग करता है, तो उन्हें जमा राशि पर 10% की वृद्धि मिलेगी।</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}